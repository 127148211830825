$primary: #52682f;
$dark: #3a3a3c;
$secondary: #fafafa;
$light: white;

// Small sm ≥576px // Medium	md	≥768px
// Large	lg	≥992px
// Extra large	xl	≥1200px
// Extra extra large	xxl	≥1400px

.h-map-image {
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  height: 500px;
}

.uppy-Dashboard-AddFiles-info {
  display: block !important;
}

.st0 {
  fill: #e0e0e0;
}

.st1 {
  fill: #dddddd;
}

.st2 {
  fill: #e8e8e8;
  opacity: 1;
}

.st3 {
  fill: #e8e8e8;
  stroke: #646464;
  stroke-width: 0;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.st4 {
  fill: #e8e8e8;
}

.st0,
.st2,
.st4 {
  cursor: pointer;
}

.st2:hover,
.st0:hover,
.st4:hover {
  fill: #d0d0d0;
}

.st2.active,
.st0.active,
.st4.active {
  fill: #6e8b3c;
}

.card-custom-class {
  width: 50%;
}

@media (max-width: 768px) {
  .card-custom-class {
    width: 100%;
  }
}

.gallery-custom .image-gallery-slides,
.gallery-custom .image-gallery-thumbnail .image-gallery-thumbnail-inner,
.image-gallery-thumbnail {
  border-radius: 8px;
  border-color: transparent;
  border: 0;
}

.gallery-custom .image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.gallery-custom .image-gallery-thumbnail:hover {
  border-radius: 12px;

  border-radius: 8px;
  border: 2px solid $primary;
}

.gallery-custom .image-gallery-thumbnail-image {
  border-radius: 12px !important;
}

.gallery-custom .image-gallery-thumbnails-container {
  text-align: start !important;
}

.light-green {
  color: #aac480 !important;
}

.bg-light-green {
  background-color: #aac480 !important;
}

@import "/node_modules/bootstrap/scss/bootstrap.scss";
